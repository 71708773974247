import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Clients } from 'src/app/interfaces/Clients';

@Component({
  selector: 'app-add-defects',
  templateUrl: './add-defects.page.html',
  styleUrls: ['./add-defects.page.scss'],
})
export class AddDefectsPage implements OnInit {

  @Input() public client: Clients;
  msg: string = "";
  modem_id: string | null = "";
  public MAX_CARACTERS_INPUT: number = 300;

  constructor(private modalController: ModalController,
    private toastController: ToastController) { }

  ngOnInit() {
    this.modem_id = this.client.modem_id;
    this.msg = this.client.info;
    console.log(this.client.info)
  }

  updateModal() {
    if (this.msg != null){
      this.modalController.dismiss(this.client);
    if (this.msg.length > this.MAX_CARACTERS_INPUT ) {
      this.presentToast();
      this.dismissModal();
    } else {
      this.client.info = this.msg;
      this.client.modem_id = this.modem_id;
      this.modalController.dismiss(this.client);
    }
    }
    else{
      this.client.modem_id = this.modem_id;
      this.modalController.dismiss(this.client);
    }
  }

  dismissModal() {
    this.modalController.dismiss(null);
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Ha sobrepasado el límite de texto introducido de '+ this.MAX_CARACTERS_INPUT + ' carácteres',
      duration: 2000,
      position: 'middle',
      color: "warning"
    });
    toast.present();
  }
}
